export const Privacidad = () => {
    return (
        <article className="legal">
            <h1>Política de Privacidad</h1>
            <p>
                De acuerdo con lo dispuesto en la normativa aplicable en materia de protección de
                datos, el interesado queda informado de que todos los datos personales recogidos en
                este folleto serán procesados con la finalidad de llevar a cabo el sorteo y la
                entrega del premio al ganador.
            </p>

            <p>
                El responsable del tratamiento de los datos de carácter personal facilitados es{' '}
                <strong>
                    {'"'}Rijk Zwaan Ibérica, S.A.{'"'}
                </strong>{' '}
                (en adelante, {'"'}Rijk Zwaan{'"'}). Se deja constancia que Rijk Zwaan y las
                empresas afiliadas operan en todo el mundo y comparten datos personales entre las
                sociedades del grupo Rijk Zwaan en caso de ser necesario para facilitar a la otra
                parte información y servicios. El interesado autoriza a Rijk Zwaan a ceder los datos
                que ha facilitado a las empresas productoras de pimiento Sweet Palermo que
                participan en la feria Fruit Attraction 2024. En caso de que sea necesario cumplir
                con las obligaciones legales o con motivo de la solicitud por las autoridades, se
                comunicarán los datos de carácter personal para este fin. Rijk Zwaan conservará los
                datos del interesado mientras sea necesario para el fin descrito anteriormente
                procediendo posteriormente a su eliminación.
            </p>

            <p>
                En el caso de que el interesado resulte ser el ganador del sorteo, se autoriza a
                Rijk Zwaan a comunicar a los demás participantes y/o publicar en redes sociales el
                nombre y apellidos del ganador. Como titular de los datos personales tratados, se
                deja constancia de que el interesado puede ejercitar los derechos de acceso,
                rectificación, supresión (olvido) y/u oposición, limitación y portabilidad de los
                datos mediante comunicación escrita dirigida a Rijk Zwaan con domicilio en Ctra
                Viator s/n, La Cañada de San Urbano (Almería) o bien, enviando un correo electrónico
                a <a href="mailto:info@rijkzwaan.es">info@rijkzwaan.es</a>.
            </p>
        </article>
    )
}
