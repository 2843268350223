import { Outlet } from 'react-router-dom'
import { Social } from '../../../componentes/social'
import { Marcas } from '../../../componentes/marcas'
import { BtnSweetPalermo } from '../../../componentes/btnSweetPalermo'

export function Layout1() {
    return (
        <>
            <main className="bg-black">
                <Outlet />
            </main>
            <aside>
                <Marcas />

                <div className="flex items-center my-4">
                    <BtnSweetPalermo />
                </div>
                <Social />
            </aside>
        </>
    )
}
