import { css } from '@emotion/css'
import { Link } from 'react-router-dom'

export const Footer = () => {
    return (
        <footer
            className={css`
                padding: 1rem;
                display: flex;
                justify-content: center;
                width: 100%;
                max-width: 450px;
                margin: 0 auto;
                a {
                    text-decoration: none;
                    font-weight: bold;
                    color: #c7c7c7;
                    transition: color linear 0.2s;
                    display: list-item;
                    list-style-type: disc;
                    list-style-position: inside;
                    margin-left: 1rem;
                    &:first-child {
                        display: block;
                    }
                    &:hover {
                        color: black;
                    }
                }
            `}>
            <Link to="/legal">legal</Link>
            <Link to="/legal/cookies/">cookies</Link>
            <Link to="/legal/privacidad">privacidad</Link>
            <Link to="/legal/bases">bases</Link>
        </footer>
    )
}
