export const Video = () => {
    return (
        <section className="video my-12">
            <iframe
                width="853"
                height="480"
                // src="https://www.youtube.com/embed/hlxyh2UFQx0" -> https://youtu.be/olnsaqc1nLU https://www.youtube.com/embed/uiI2LQKWUsg
                src="https://www.youtube.com/embed/Jhl_hKrA-6E?si=YM7ZVYWsCPtDFQpF"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </section>
    )
}
