import { useEffect, useState } from 'react'
import { useParticipar } from '../../../hooks/useParticipacion'
import { useUsuario } from '../../../hooks/useUsuario'
import { clientesFeria, clientesRedes } from '../../../modelos/cliente'
import { Estados } from '../../../utilidades/constants'
import Cargando from './cargando'
import { Mensaje } from './mensaje'
import { Recuerda } from './recuerda'

export const Resultado = ({ cliente, usuario, fromRedes }) => {
    const [error, setError] = useState()
    const { puedeParticipar, participar, haParticipado } = useParticipar()
    const [estado, setEstado] = useState(Estados.PARTICIPANDO)

    useEffect(() => {
        setError(undefined)
        setEstado(Estados.PARTICIPANDO)
        const clientes = fromRedes ? clientesRedes : clientesFeria

        const guardarParticipacion = async () => {
            try {
                const finalRedes = usuario.numParticipacionesRedes + 1 === clientesRedes.length
                const finalFeria = usuario.numParticipaciones + 1 === clientesFeria.length
                const numParticipaciones =
                    (fromRedes ? usuario.numParticipacionesRedes : usuario.numParticipaciones) || 0
                console.log(
                    usuario.numParticipaciones,
                    usuario.numParticipacionesRedes,
                    usuario,
                    numParticipaciones,
                )
                await participar(cliente, fromRedes)
                if (numParticipaciones === 0) {
                    setEstado(Estados.PRIMERA)
                } else if (finalRedes && !finalFeria) {
                    setEstado(Estados.FIN_REDES)
                } else if (finalFeria && !finalRedes) {
                    setEstado(Estados.FIN_FERIA)
                } else if (numParticipaciones + 1 < clientes.length) {
                    setEstado(Estados.VARIAS)
                } else {
                    setEstado(Estados.ULTIMA)
                }
            } catch (error) {
                setEstado(Estados.ERROR)
                setError(error?.message || error)
            }
        }

        if (!cliente) {
            setEstado(Estados.ERROR)
            setError('Ups.. este destino no existe.')
        } else if (usuario?.reference?.id) {
            if (haParticipado(cliente, fromRedes)) {
                setEstado(Estados.HA_PARTICIPADO)
            } else if (puedeParticipar(cliente, fromRedes)) {
                guardarParticipacion()
            } else {
                setError('A este destino ya llegaste!')
                setEstado(Estados.ERROR)
            }
        } else {
            setError('No se ha podido verificar el usuario. Vuelva a intentarlo.')
            setEstado(Estados.ERROR)
        }
    }, [cliente, fromRedes])

    // eslint-disable-next-line no-constant-condition
    // if (true) {
    // console.log(error, estado)
    //     return <PantallaVarias fromRedes={true} />
    // }
    if (estado === Estados.PARTICIPANDO) return <Cargando />

    if (estado === Estados.VARIAS) {
        return <PantallaVarias fromRedes={fromRedes} />
    }

    if (estado === Estados.PRIMERA) {
        return <PantallaPrimera fromRedes={fromRedes} />
    }

    if (
        estado === Estados.ERROR ||
        estado === Estados.NO_VALIDO ||
        estado === Estados.HA_PARTICIPADO
    ) {
        return <PantallaError fromRedes={fromRedes} repetido={estado === Estados.HA_PARTICIPADO} />
    }

    if (estado === Estados.FIN_FERIA) {
        return <PantallaFinalParcial fromRedes={estado === Estados.FIN_REDES} />
    }

    if (estado === Estados.ULTIMA || estado === Estados.FIN_REDES) {
        return <PantallaFinal fromRedes={fromRedes} />
    }

    return <>{error}</>
}

/**
 * Entre 1 y clientes.length - 1 participaciones
 */
const PantallaVarias = ({ fromRedes }) => {
    const { usuario } = useUsuario()
    const numParticipacionesRedes = usuario['numParticipacionesRedes'] || 0

    const mensaje = fromRedes
        ? `Has conseguido ${numParticipacionesRedes} ${numParticipacionesRedes === 1 ? 'participación' : 'participaciones'} para el sorteo`
        : '¡Nuevo destino añadido a tu lista!'
    const icono = fromRedes
        ? '/assets/img/resultados/varias.png'
        : '/assets/img/resultados/varias.png'
    const imagen = fromRedes
        ? '/assets/img/resultados/primera-bg.jpg'
        : '/assets/img/resultados/primera-bg.jpg'
    return (
        <>
            <div
                style={{ '--image-url': `url('${imagen}')` }}
                className={`relative bg-[image:var(--image-url)] bg-cover bg-no-repeat bg-top max-w-sm mx-auto pb-24`}>
                <Mensaje mensaje={mensaje} icono={icono} />
                <img
                    className="max-w-xs mx-auto mt-6 px-8"
                    src="/assets/img/sabor.png"
                    alt={mensaje}
                />
                {!fromRedes ? (
                    <Recuerda
                        className="max-w-xs mx-auto"
                        estado={Estados.VARIAS}
                        fromRedes={false}
                    />
                ) : (
                    <div className="h-[250px]" />
                )}
                <div className="max-w-xs mx-auto mt-28 mb-20">
                    <img
                        className="max-w-48 mx-auto"
                        src="/assets/img/recuerda.png"
                        alt="recuerda"
                    />
                    <p className="text-white font-semibold text-xl text-center">
                        En cada stand, te esperan Sabores a Primera vista por descubrir
                    </p>
                </div>
                {!fromRedes && (
                    <div className="max-w-xs mx-auto pt-8">
                        <img src="/assets/img/resultados/oportunidad.png" alt="oportunidad" />
                    </div>
                )}
            </div>
        </>
    )
}

/**
 * Primera participación
 */
const PantallaPrimera = ({ fromRedes }) => {
    const mensaje = fromRedes
        ? 'Has conseguido 1 participación para el sorteo.'
        : 'Has llegado al primer Destino de Sabor'
    const icono = fromRedes
        ? '/assets/img/resultados/primera.png'
        : '/assets/img/resultados/primera.png'
    const imagen = fromRedes
        ? '/assets/img/resultados/primera-bg.jpg'
        : '/assets/img/resultados/primera-bg.jpg'

    return (
        <div
            style={{ '--image-url': `url('${imagen}')` }}
            className={`relative bg-[image:var(--image-url)] bg-cover bg-no-repeat bg-top max-w-sm mx-auto pb-24`}>
            <Mensaje mensaje={mensaje} icono={icono} />
            <img className="max-w-xs mx-auto mt-6 px-8" src="/assets/img/sabor.png" alt={mensaje} />
            {!fromRedes && (
                <Recuerda className="max-w-xs mx-auto" estado={Estados.PRIMERA} fromRedes={false} />
            )}
            <div className="max-w-xs mx-auto mt-24 mb-20">
                <img className="max-w-48 mx-auto" src="/assets/img/recuerda.png" alt="recuerda" />
                <p className="text-white font-semibold text-xl text-center">
                    En cada stand, te esperan Sabores a Primera vista por descubrir
                </p>
            </div>
            {!fromRedes && (
                <div className="max-w-xs mx-auto pt-8">
                    <img src="/assets/img/resultados/oportunidad.png" alt="oportunidad" />
                </div>
            )}
        </div>
    )
}

const PantallaError = ({ repetido = false }) => {
    const mensaje = repetido ? 'A este destino ya llegaste' : 'Ups.. este destino no existe.'
    const icono = '/assets/img/resultados/error.png'
    const imagen = '/assets/img/resultados/error-bg.jpg'
    return (
        <>
            <div
                style={{ '--image-url': `url('${imagen}')` }}
                className={`relative bg-[image:var(--image-url)] bg-cover bg-no-repeat bg-top max-w-sm mx-auto pb-24`}>
                <Mensaje mensaje={mensaje} icono={icono} />
                <img
                    className="max-w-xs mx-auto mt-6 px-8"
                    src="/assets/img/sabor.png"
                    alt={mensaje}
                />

                <Recuerda
                    className="max-w-xs mx-auto"
                    estado={repetido ? Estados.HA_PARTICIPADO : Estados.ERROR}
                    fromRedes={false}
                />
                <div className="max-w-xs mx-auto mt-24 mb-20">
                    <img
                        className="max-w-48 mx-auto"
                        src="/assets/img/recuerda.png"
                        alt="recuerda"
                    />
                    <p className="text-white font-semibold text-xl text-center">
                        En cada stand, te esperan Sabores a Primera vista por descubrir
                    </p>
                </div>
            </div>
        </>
    )
}

const PantallaFinal = () => {
    const imagen = '/assets/img/resultados/final-bg.jpg'
    const icono = '/assets/img/resultados/llegado.png'
    const mensaje =
        '¡Has conseguido todas las participaciones para el sorteo de una increíble Thermomix TM6 de última generación!'

    return (
        <div
            style={{ '--image-url': `url('${imagen}')` }}
            className={`relative bg-[image:var(--image-url)] bg-cover bg-no-repeat bg-top max-w-sm mx-auto pb-24`}>
            <Mensaje mensaje={mensaje} icono={icono} />
            <img className="max-w-xs mx-auto mt-6 px-8" src="/assets/img/sabor.png" alt={mensaje} />
            <img
                className="max-w-xs mx-auto mt-16"
                src="/assets/img/resultados/enhorabuena.png"
                alt={mensaje}
            />
            <div className="max-w-xs mx-auto mt-20 mb-20">
                <p className="text-white font-semibold text-xl text-center mb-0">
                    Muchas gracias por participar y disfruta del Sabor a Primera Vista
                </p>
            </div>
        </div>
    )
}

const PantallaFinalParcial = ({ fromRedes }) => {
    const clientes = fromRedes ? clientesRedes : clientesFeria
    const imagen = fromRedes
        ? '/assets/img/resultados/parcialredes-bg.jpg'
        : '/assets/img/resultados/parcial-bg.jpg'
    const icono = fromRedes
        ? '/assets/img/resultados/alucinante.png'
        : '/assets/img/resultados/gran-trabajo.png'
    const mensaje = fromRedes
        ? `Has sumado ${clientes.length} participaciones extra para el sorteo de una increíble Thermomix TM6 de última generación`
        : `¡${clientes.length} destinos alcanzados para el sorteo!`

    const conseguidoImg = fromRedes
        ? '/assets/img/resultados/felicidades.png'
        : '/assets/img/resultados/conseguido.png'
    console.log(conseguidoImg)
    return (
        <div
            style={{ '--image-url': `url('${imagen}')` }}
            className={`relative bg-[image:var(--image-url)] bg-cover bg-no-repeat bg-top max-w-sm mx-auto pb-8`}>
            <Mensaje mensaje={mensaje} icono={icono} />
            <img className="max-w-xs mx-auto mt-6 px-8" src="/assets/img/sabor.png" alt={mensaje} />
            <img className="max-w-xs mx-auto mt-16" src={conseguidoImg} alt={mensaje} />
            {!fromRedes && (
                <div className="max-w-xs mx-auto mt-24 mb-20">
                    <p className="text-white font-bold text-xl text-center leading-6">
                        ¿Te gustaría sumar más oportunidades para ganar?
                    </p>
                    <p className="text-white font-normal text-xl text-center leading-6">
                        Simplemente visita las redes sociales de las empresas participantes y sigue
                        el enlace de Sabor a primera vista.
                    </p>
                    <p className="text-white font-normal text-xl text-center">¡Así de fácil!</p>
                </div>
            )}
            <div className={`${fromRedes ? 'min-h-[140px]' : 'min-h-[140px]'}`} />
            {!fromRedes && (
                <div className="max-w-xs mx-auto mb-20">
                    <p className="text-white font-semibold text-xl text-center mb-0">
                        En breve te informaremos si has ganado una increíble Thermomix TM6 de última
                        generación y nos pondremos en contacto contigo.
                    </p>
                    <p className="text-white font-semibold text-xl text-center">
                        Gracias por tu participación.
                    </p>
                </div>
            )}
        </div>
    )
}
