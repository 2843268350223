import { useUsuario } from '../../../hooks/useUsuario'
import { clientesFeria, clientesRedes } from '../../../modelos/cliente'
import { Estados } from '../../../utilidades/constants'

const getText = (estado) => {
    switch (estado) {
        case Estados.HA_PARTICIPADO:
            return {
                title: ['¡No te detengas.', 'Continúa sumando destinos a tu lista!'],
                descriptionBold:
                    'Si continúas descubriendo destinos hacía el Sabor, tendrás más opciones de ganar una Thermomix TM6 de última generación',
                description: [],
            }
        case Estados.PRIMERA:
            return {
                title: ['Ya tienes 1 participación para el sorteo.'],
                subtitle: ['Te quedan YYY destinos por descubrir.'],
                descriptionBold: 'Continúna tu viaje hacia el sabor.',
                description: [
                    'Escaneando más códigos QR en los stands asociados, aumentarás tus oportunidades de ganar.',
                ],
            }
        case Estados.VARIAS:
            return {
                title: ['Ya tienes XXX participaciones para el sorteo.'],
                subtitle: ['YYY destinos aún te esperan.'],
                descriptionBold: 'Continúna tu viaje hacia el sabor.',
                description: [
                    'Escaneando más códigos QR en los stands asociados, aumentarás tus oportunidades de ganar.',
                ],
            }
        default:
            return {
                title: ['¡No te detengas.', 'Continúa sumando destinos a tu lista!'],
                descriptionBold:
                    'Si continúas descubriendo destinos hacía el Sabor, tendrás más opciones de ganar una Thermomix TM6 de última generación',
                description: [],
            }
    }
}

export const Recuerda = ({ estado, fromRedes, className = '' }) => {
    const { usuario } = useUsuario()

    const mostrarRecuerda = (estado !== estado) !== Estados.VARIAS

    if (!mostrarRecuerda) return null

    const { title, subtitle, description, descriptionBold } = getText(estado)

    const parseText = (text, fromRedes) => {
        const field = fromRedes ? 'numParticipacionesRedes' : 'numParticipaciones'
        const clientes = fromRedes ? clientesRedes : clientesFeria
        return text.map((line, idx) => {
            const parsedLine = line
                .replaceAll('XXX', usuario[field])
                .replaceAll('YYY', clientes.length - usuario[field])
            return (
                <span key={idx}>
                    {parsedLine}
                    {idx < title.length - 1 && <br />}
                </span>
            )
        })
    }

    return (
        <div className={className}>
            <div className="relative bg-white rounded-xl overflow-hidden mt-8 mb-10 mx-0">
                <div className="bg-[#e1001e] text-white p-4">
                    <h1 className="text-center font-semibold text-2xl leading-6 mb-0">
                        {parseText(title, fromRedes)}
                    </h1>
                    {subtitle && (
                        <h2 className="text-center text-base m-0 mt-4 leading-5 font-normal">
                            {parseText(subtitle, fromRedes)}
                        </h2>
                    )}
                </div>
                <div className="p-4 ">
                    {descriptionBold && (
                        <p className="font-semibold mb-0 leading-5 text-center">
                            {descriptionBold}
                        </p>
                    )}
                    <p className="font-normal text-center leading-5">
                        {parseText(description, fromRedes)}
                    </p>
                </div>
            </div>
        </div>
    )
}
