import { ErrorMessage } from '@hookform/error-message'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useAuth } from '../hooks/useUsuario'
import { getError } from '../utilidades/errores'

export const RegistroForm = () => {
    const [error, setError] = useState()
    const { registro } = useAuth()
    const {
        handleSubmit,
        register,
        formState: { isDirty, isValid, isSubmitting, errors },
    } = useForm({
        reValidateMode: 'onChange',
        mode: 'onChange',
    })

    const onSubmit = async (data) => {
        setError(undefined)
        try {
            const { password, ...usuario } = data
            await registro(usuario, password)
        } catch (error) {
            console.error(error)
            setError(getError(error))
        }
    }

    return (
        <>
            <form className="flex flex-col gap-8" onSubmit={handleSubmit(onSubmit)}>
                <div className="p-4">
                    <h1 className="text-3xl mb-4">
                        Apúntate y podrás ganar una increíble Thermomix TM6 de última generación
                    </h1>
                    <label htmlFor="nombre">
                        <p>Nombre </p>
                        <input
                            id="nombre"
                            type="text"
                            defaultValue=""
                            {...register('nombre', {
                                required: {
                                    value: true,
                                    message: 'Este campo es obligatorio.',
                                },
                                minLength: {
                                    value: 3,
                                    message: 'Debe contener al menos 3 dígitos.',
                                },
                            })}
                        />
                        <ErrorMessage errors={errors} name="nombre" />
                    </label>
                    <label htmlFor="apellidos">
                        <p>Apellidos </p>
                        <input
                            id="apellidos"
                            type="text"
                            defaultValue=""
                            {...register('apellidos')}
                        />
                        <ErrorMessage errors={errors} name="apellidos" />
                    </label>
                    <label htmlFor="direccion">
                        <p>Dirección </p>
                        <input
                            id="direccion"
                            type="text"
                            defaultValue=""
                            {...register('direccion', {
                                required: {
                                    value: true,
                                    message: 'Este campo es obligatorio.',
                                },
                                minLength: {
                                    value: 3,
                                    message: 'Debe contener al menos 3 dígitos',
                                },
                            })}
                        />
                        <ErrorMessage errors={errors} name="direccion" />
                    </label>
                    <label htmlFor="cp">
                        <p>Código postal </p>
                        <input
                            id="cp"
                            type="number"
                            defaultValue=""
                            {...register('cp', {
                                required: {
                                    value: true,
                                    message: 'Este campo es obligatorio.',
                                },
                                minLength: {
                                    value: 5,
                                    message: 'Debe contener al menos 5 dígitos',
                                },
                            })}
                        />
                        <ErrorMessage errors={errors} name="cp" />
                    </label>
                    <label htmlFor="ciudad">
                        <p>Ciudad </p>
                        <input
                            id="ciudad"
                            type="text"
                            defaultValue=""
                            {...register('ciudad', {
                                required: {
                                    value: true,
                                    message: 'Este campo es obligatorio.',
                                },
                                minLength: {
                                    value: 3,
                                    message: 'Debe contener al menos 3 dígitos',
                                },
                            })}
                        />
                        <ErrorMessage errors={errors} name="ciudad" />
                    </label>
                    <label htmlFor="pais">
                        <p>País </p>
                        <input
                            id="pais"
                            type="text"
                            defaultValue=""
                            {...register('pais', {
                                required: {
                                    value: true,
                                    message: 'Este campo es obligatorio.',
                                },
                                minLength: {
                                    value: 3,
                                    message: 'Debe contener al menos 3 dígitos',
                                },
                            })}
                        />
                        <ErrorMessage errors={errors} name="pais" />
                    </label>
                    <label htmlFor="empresa">
                        <p>Empresa </p>
                        <input id="empresa" type="text" defaultValue="" {...register('empresa')} />
                        <ErrorMessage errors={errors} name="empresa" />
                    </label>
                    <label htmlFor="cargo">
                        <p>Cargo </p>
                        <input id="cargo" type="text" defaultValue="" {...register('cargo')} />
                        <ErrorMessage errors={errors} name="cargo" />
                    </label>
                    <label htmlFor="movil">
                        <p>Móvil </p>
                        <input
                            id="movil"
                            type="tel"
                            defaultValue=""
                            {...register('movil', {
                                required: {
                                    value: true,
                                    message: 'Este campo es obligatorio.',
                                },
                                minLength: {
                                    value: 9,
                                    message: 'Debe contener al menos 9 dígitos',
                                },
                            })}
                        />
                        <ErrorMessage errors={errors} name="movil" />
                    </label>
                    <label htmlFor="email">
                        <p>Email </p>
                        <input
                            id="email"
                            type="email"
                            defaultValue=""
                            {...register('email', {
                                required: {
                                    value: true,
                                    message: 'Este campo es obligatorio.',
                                },
                                minLength: {
                                    value: 5,
                                    message: 'Debe contener al menos 5 dígitos',
                                },
                            })}
                        />
                        <ErrorMessage errors={errors} name="email" />
                    </label>
                    <label htmlFor="password">
                        <p>Contraseña </p>
                        <input
                            id="password"
                            type="password"
                            defaultValue=""
                            {...register('password', {
                                required: {
                                    value: true,
                                    message: 'Este campo es obligatorio.',
                                },
                                minLength: {
                                    value: 6,
                                    message: 'Debe contener al menos 6 dígitos',
                                },
                            })}
                        />
                        <ErrorMessage errors={errors} name="password" />
                    </label>
                </div>
                <div className="bg-black text-white px-4">
                    <label htmlFor="aceptaPrivacidad" className="checkbox">
                        <input
                            id="aceptaPrivacidad"
                            type="checkbox"
                            {...register('aceptaPrivacidad', {
                                required: {
                                    value: true,
                                    message:
                                        'Debe aceptar la política de privacidad para poder participar.',
                                },
                            })}
                        />
                        <span className="checkmark" />
                        <p>
                            He leído y acepto la{' '}
                            <Link target="_blank" to="/legal/privacidad">
                                Política de Privacidad
                            </Link>
                        </p>
                        <ErrorMessage errors={errors} name="aceptaPrivacidad" />
                    </label>

                    <label htmlFor="aceptaBases" className="checkbox">
                        <input
                            id="aceptaBases"
                            type="checkbox"
                            {...register('aceptaBases', {
                                required: {
                                    value: true,
                                    message:
                                        'Debe aceptar las bases de la promoción para poder participar.',
                                },
                            })}
                        />
                        <span className="checkmark" />
                        <p>
                            He leído y acepto las{' '}
                            <Link target="_blank" to="/legal/bases">
                                Bases de la Promoción
                            </Link>
                        </p>
                        <ErrorMessage errors={errors} name="aceptaBases" />
                    </label>
                    <label htmlFor="aceptaInformacion" className="checkbox">
                        <input
                            id="aceptaInformacion"
                            type="checkbox"
                            defaultChecked
                            {...register('aceptaInformacion')}
                        />
                        <span className="checkmark" />
                        <p className="!pt-0">
                            Acepto recibir información comercial de Sabor a primera vista®
                        </p>
                        <ErrorMessage errors={errors} name="aceptaInformacion" />
                    </label>
                    {error && <p className="error">{error}</p>}
                </div>
                <div className="text-center bg-[#f2f2f2] px-4">
                    <button disabled={!isDirty || !isValid || isSubmitting} type="submit">
                        PARTICIPA
                    </button>
                </div>
            </form>
        </>
    )
}
