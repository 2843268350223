import { useState } from 'react'
import { LoginForm } from './loginForm'
import { RegistroForm } from './registroForm'

export const Autenticacion = () => {
    const [isLogin, setIsLogin] = useState(false)

    const toggle = () => {
        setIsLogin(!isLogin)
    }

    return (
        <section className="flex flex-col gap-4 items-center pb-8 bg-[#f2f2f2]">
            {isLogin ? <LoginForm /> : <RegistroForm />}
            <div className="bg-[#f2f2f2] w-full">
                {isLogin && (
                    <div className="flex gap-4  justify-between items-center">
                        <p className="px-4 m-0">¿No tienes cuenta? </p>
                        <button
                            className="bg-white text-black inline-block w-auto cursor-pointer text-base py-2"
                            type="button"
                            onClick={toggle}>
                            Regístrate aquí
                        </button>
                    </div>
                )}
                {!isLogin && (
                    <div className="flex gap-4  justify-between items-center">
                        <p className="px-4 m-0">¿Ya tienes cuenta? </p>
                        <button
                            className="bg-white text-black inline-block w-auto cursor-pointer text-base py-2"
                            type="button"
                            onClick={toggle}>
                            Inicia sesión
                        </button>
                    </div>
                )}
            </div>
        </section>
    )
}
