import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Autenticacion } from '../../componentes/autenticacion'
import { useParticipar } from '../../hooks/useParticipacion'
import { useUsuario } from '../../hooks/useUsuario'
import Cargando from './componentes/cargando'
import Inicio from './componentes/inicio'
import { Resultado } from './componentes/resultado'

export const Participar = ({ fromRedes = false }) => {
    const { cliente: param } = useParams()
    const { getCliente } = useParticipar()
    const cliente = getCliente(param, fromRedes)
    const { usuario, loading } = useUsuario()
    const [showHome, setShowHome] = useState(true)

    if (loading) {
        return <Cargando />
    }

    if (fromRedes && !usuario && showHome) {
        return <Inicio onParticipar={() => setShowHome(false)} />
    }

    if (!usuario) {
        return <Autenticacion />
    }

    return <Resultado cliente={cliente} usuario={usuario} fromRedes={fromRedes} />
}
