import { TEMPORADA } from '../utilidades/constants'

/**
https://www.saboraprimeravista.es/participa/agroejido
https://www.saboraprimeravista.es/participa/agroiris
https://www.saboraprimeravista.es/participa/agroponiente
https://www.saboraprimeravista.es/participa/balcondenijar
https://www.saboraprimeravista.es/participa/biosolportocarrero
https://www.saboraprimeravista.es/participa/camposeven
https://www.saboraprimeravista.es/participa/ecoinver
https://www.saboraprimeravista.es/participa/ejidomar
https://www.saboraprimeravista.es/participa/fruca
https://www.saboraprimeravista.es/participa/hortamar
https://www.saboraprimeravista.es/participa/indasol
https://www.saboraprimeravista.es/participa/gava
https://www.saboraprimeravista.es/participa/lashortichuelas
https://www.saboraprimeravista.es/participa/naturaldemontana
https://www.saboraprimeravista.es/participa/naturechoice
https://www.saboraprimeravista.es/participa/unica
https://www.saboraprimeravista.es/participa/rijkzwaan
https://www.saboraprimeravista.es/participa/sweetpalermo
 */

const clientes = [
    {
        nombre: 'Agroejido',
        url: '/participar/agroejido',
        codigo: 'agroejido',
        logo: `/assets/img/marcas/${TEMPORADA}/agroejido.png`,
        descripcion: '',
        stand: '9C19',
        pabellon: '9',
    },
    {
        nombre: 'Agroiris',
        url: '/participar/agroiris',
        codigo: 'agroiris',
        logo: `/assets/img/marcas/${TEMPORADA}/agroiris.png`,
        descripcion: '',
        stand: '9C14',
        pabellon: '9',
    },
    {
        nombre: 'Agroponiente',
        url: '/participar/agroponiente',
        codigo: 'agroponiente',
        logo: `/assets/img/marcas/${TEMPORADA}/agroponiente.png`,
        descripcion: '',
        stand: '9D05',
        pabellon: '9',
    },
    {
        nombre: 'Balcón de Níjar',
        url: '/participar/balcondenijar',
        codigo: 'balcondenijar',
        logo: `/assets/img/marcas/${TEMPORADA}/balcondenijar.png`,
        descripcion: '',
        stand: '9B07',
        pabellon: '9',
    },
    {
        nombre: 'Biosol Portocarrero',
        url: '/participar/biosolportocarrero',
        codigo: 'biosolportocarrero',
        logo: `/assets/img/marcas/${TEMPORADA}/biosolportocarrero.png`,
        descripcion: '',
        stand: '9B17',
        pabellon: '9',
    },
    {
        nombre: 'Camposeven',
        url: '/participar/camposeven',
        codigo: 'camposeven',
        logo: `/assets/img/marcas/${TEMPORADA}/camposeven.png`,
        descripcion: '',
        stand: '7A06A',
        pabellon: '7',
    },
    {
        nombre: 'Ecoinver',
        url: '/participar/ecoinver',
        codigo: 'ecoinver',
        logo: `/assets/img/marcas/${TEMPORADA}/ecoinver.png`,
        descripcion: '',
        stand: '9C09A',
        pabellon: '9',
    },
    {
        nombre: 'Ejidomar',
        url: '/participar/ejidomar',
        codigo: 'ejidomar',
        logo: `/assets/img/marcas/${TEMPORADA}/ejidomar.png`,
        descripcion: '',
        stand: '9B08',
        pabellon: '9',
    },
    {
        nombre: 'Fruca',
        url: '/participar/fruca',
        codigo: 'fruca',
        logo: `/assets/img/marcas/${TEMPORADA}/fruca.png`,
        descripcion: '',
        stand: '7C08',
        pabellon: '7',
    },
    {
        nombre: 'Hortamar',
        url: '/participar/hortamar',
        codigo: 'hortamar',
        logo: `/assets/img/marcas/${TEMPORADA}/hortamar.png`,
        descripcion: '',
        stand: '9B08A',
        pabellon: '9',
    },
    {
        nombre: 'Indasol',
        url: '/participar/indasol',
        codigo: 'indasol',
        logo: `/assets/img/marcas/${TEMPORADA}/indasol.png`,
        descripcion: '',
        stand: '9C05',
        pabellon: '9',
    },
    {
        nombre: 'Gava Group',
        url: '/participar/gava',
        codigo: 'gava',
        logo: `/assets/img/marcas/${TEMPORADA}/gava.png`,
        descripcion: '',
        stand: '9A14',
        pabellon: '9',
    },
    {
        nombre: 'Hortichuelas',
        url: '/participar/lashortichuelas',
        codigo: 'lashortichuelas',
        logo: `/assets/img/marcas/${TEMPORADA}/lashortichuelas.png`,
        descripcion: '',
        stand: '9D13',
        pabellon: '9',
    },
    {
        nombre: 'Natural de Montaña',
        url: '/participar/naturaldemontana',
        codigo: 'naturaldemontana',
        logo: `/assets/img/marcas/${TEMPORADA}/naturaldemontana.png`,
        descripcion: '',
        stand: '3D08',
        pabellon: '3',
    },
    {
        nombre: 'Nature Choice',
        url: '/participar/naturechoice',
        codigo: 'naturechoice',
        logo: `/assets/img/marcas/${TEMPORADA}/naturechoice.png`,
        descripcion: '',
        stand: '9C07',
        pabellon: '9',
    },
    {
        nombre: 'Única',
        url: '/participar/unica',
        codigo: 'unica',
        logo: `/assets/img/marcas/${TEMPORADA}/unica.png`,
        descripcion: '',
        stand: '9D03',
        pabellon: '9',
    },
    {
        nombre: 'Rijk Zwaan',
        url: '/participar/rijkzwaan',
        codigo: 'rijkzwaan',
        logo: `/assets/img/marcas/${TEMPORADA}/rijkzwaan.png`,
        descripcion: '',
        stand: '9D23',
        pabellon: '9',
    },

    // {
    //     nombre: 'Bio Campojoyma',
    //     url: '/participar/campojoyma',
    //     codigo: 'campojoyma',
    //     logo: `/assets/img/marcas/${TEMPORADA}/campojoyma.png`,
    //     descripcion: '',
    //     stand: '9B11',
    //     pabellon: '9',
    // },
    // {
    //     nombre: 'Vicasol',
    //     url: '/participar/vicasol',
    //     codigo: 'vicasol',
    //     logo: `/assets/img/marcas/${TEMPORADA}/vicasol.png`,
    //     descripcion: '',
    //     stand: '9B02',
    //     pabellon: '9',
    // },
]

export const clientesFeria = clientes
export const clientesRedes = [
    ...clientes,
    {
        nombre: 'Sweet Palermo',
        url: '/participar/sweetpalermo',
        codigo: 'sweetpalermo',
        descripcion: '',
        stand: '9D23',
        pabellon: '9',
    },
]
