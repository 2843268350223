import { clientesFeria, clientesRedes } from '../modelos/cliente'
import * as usuariosService from '../servicios/usuarios'
import { useUsuario } from './useUsuario'

export const useParticipar = () => {
    const { usuario } = useUsuario()

    const getClientes = (fromRedes) => {
        return fromRedes ? clientesRedes : clientesFeria
    }

    const getCliente = (url, fromRedes) => {
        const split = url.split('/')
        if (split.length > 0) {
            const codigo = split[split.length - 1]
            return getClientes(fromRedes).find((c) => c.codigo === codigo)
        }
        return undefined
    }

    const puedeParticipar = (cliente, fromRedes) => {
        if (!usuario?.reference?.id || !cliente) return false
        if (usuario.participaciones.length >= getClientes(fromRedes).length * 2) return false
        const origen = fromRedes ? 'Redes' : 'Feria'
        const participacion = usuario.participaciones.find(
            (p) => p.codigoCliente === cliente.codigo && p.origen === origen,
        )
        return !participacion
    }

    const haParticipado = (cliente, fromRedes) => {
        if (!usuario?.reference?.id) return false
        if (cliente) {
            const origen = fromRedes ? 'Redes' : 'Feria'
            const participacion = usuario.participaciones.find(
                (p) => p.codigoCliente === cliente.codigo && p.origen === origen,
            )
            return !!participacion
        }

        return false
    }

    const participar = async (cliente, fromRedes) => {
        if (!usuario?.reference?.id) throw new Error('No ha iniciado sesión')
        if (!cliente) throw new Error('Código no válido')
        if (cliente && !haParticipado(cliente, fromRedes)) {
            await usuariosService.participar(usuario, cliente, fromRedes)
        }
    }

    return { participar, haParticipado, getCliente, puedeParticipar }
}
