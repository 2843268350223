import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import { clientesFeria } from '../modelos/cliente'
import { Item } from '../modulos/home/componentes/item'

const marcas = clientesFeria.map((cliente) => (
    <Item
        key={cliente.codigo}
        image={cliente.logo.replace('/marcas/', '/slider/').slice(1)}
        alt={cliente.nombre}
        pabellon={cliente.pabellon}
        stand={cliente.stand}
    />
))

export const Marcas = () => {
    return (
        <section
            className="py-8 px-4 flex flex-col flex-wrap w-full"
            // className={css`
            //     padding: 2rem 0;
            //     text-align: center;
            //     display: flex;
            //     flex-direction: column;
            //     width: 100%;
            //     .alice-carousel__dots {
            //         margin: 2rem 0 0 0;
            //         li {
            //             background: #ecf1f7;
            //             margin-right: 1rem !important;
            //             &.__active {
            //                 background: #c7c7c7;
            //             }
            //         }
            //     }
            // `}>
        >
            <h2 className="font-semibold text-center px-4 pt-8 pb-4">Empresas comercializadoras</h2>
            <AliceCarousel
                autoPlay
                autoPlayInterval={2000}
                infinite
                mouseTracking
                disableButtonsControls
                items={marcas}
                paddingLeft={10}
                paddingRight={10}
                responsive={{
                    0: {
                        items: 3,
                    },
                    500: {
                        items: 3,
                    },
                }}
            />
        </section>
    )
}
