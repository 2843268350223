export const TEMPORADA = '2024'

export const Estados = {
    PARTICIPANDO: 'PARTICIPANDO',
    PRIMERA: 'PRIMERA',
    VARIAS: 'VARIAS',
    ULTIMA: 'ULTIMA',
    FIN_REDES: 'FIN_REDES',
    FIN_FERIA: 'FIN_FERIA',
    EXTRA: 'EXTRA',
    HA_PARTICIPADO: 'HA_PARTICIPADO',
    NO_VALIDO: 'NO_VALIDO',
    ERROR: 'ERROR',
}
