import { BtnParticipar } from '../../componentes/btnParticipar'
import { BtnSweetPalermo } from '../../componentes/btnSweetPalermo'
import { Video } from './componentes/video'

export const Home = () => {
    return (
        <>
            <section className="max-w-2xl mx-auto">
                <div className="relative">
                    <div className="flex items-center mt-8 absolute w-full top-[22%] md:top-72">
                        <BtnSweetPalermo label="Conoce Sweet Palermo" className="opacity-80" />
                    </div>
                    <img
                        src="/assets/img/home1.jpg"
                        alt="Participa y gana una experiencia en un restaurante 3 estrellas michelín"
                    />
                </div>
            </section>
            <div className="wrapper">
                <Video />
                <BtnParticipar />
                <div className="pb-16 max-w-sm mx-auto">
                    <img src="/assets/img/home2.png" alt="logos" />
                </div>
            </div>
        </>
    )
}
