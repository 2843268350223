import { css } from '@emotion/css'
import { ErrorMessage } from '@hookform/error-message'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from '../hooks/useUsuario'
import { getError } from '../utilidades/errores'

export const LoginForm = ({ botonLabel = 'PARTICIPA' }) => {
    const [error, setError] = useState()
    const [mensajeRemember, setMensajeRemember] = useState()
    const { login, remember } = useAuth()
    const {
        handleSubmit,
        register,
        formState: { isDirty, isValid, isSubmitting, errors },
    } = useForm({
        reValidateMode: 'onChange',
        mode: 'onChange',
    })

    const onSubmit = async (data) => {
        const { email, password } = data
        setError(undefined)
        try {
            await login(email, password)
        } catch (error) {
            setError(getError(error))
        }
    }

    const recordar = async (event) => {
        const email = event.target.form[0]?.value
        if (!email) {
            return setMensajeRemember('Debes introducir un email')
        }
        try {
            await remember(email)
            setMensajeRemember(
                'Se ha enviado un email a tu cuenta para que puedas recuperar tu contraseña.',
            )
        } catch (error) {
            setMensajeRemember(getError(error))
        }
    }

    return (
        <form className="flex flex-col gap-8" onSubmit={handleSubmit(onSubmit)}>
            <div className="p-4">
                <h1
                    className={css`
                        font-size: 2rem;
                    `}>
                    Inicia sesión en saboraprimeravista®
                </h1>
                <label htmlFor="email">
                    <p>Email: </p>
                    <input
                        id="email"
                        type="email"
                        defaultValue=""
                        {...register('email', {
                            required: {
                                value: true,
                                message: 'El campo es obligatorio.',
                            },
                            minLength: {
                                value: 5,
                                message: 'Debe contener al menos 5 dígitos.',
                            },
                        })}
                    />
                    <ErrorMessage errors={errors} name="email" />
                </label>
                <label htmlFor="email">
                    <p>Contraseña: </p>
                    <input
                        id="password"
                        type="password"
                        defaultValue=""
                        {...register('password', {
                            required: {
                                value: true,
                                message: 'El campo es obligatorio.',
                            },
                            minLength: {
                                value: 6,
                                message: 'Debe contener al menos 6 dígitos.',
                            },
                        })}
                    />
                    <ErrorMessage errors={errors} name="password" />
                </label>
                <button
                    disabled={!isDirty || !isValid || isSubmitting}
                    type="submit"
                    className={css`
                        margin-top: 1rem;
                        width: 100%;
                    `}>
                    {botonLabel}
                </button>
                {error && <p className="error">{error}</p>}
            </div>
            <div className="flex gap-4 justify-between items-center">
                <p className="px-4 m-0">¿No recuerdas la contraseña? </p>
                <button
                    className="bg-white text-black inline-block w-auto cursor-pointer text-base py-2"
                    type="button"
                    onClick={recordar}>
                    Cambiar
                </button>
                {mensajeRemember && <p>{mensajeRemember}</p>}
            </div>
        </form>
    )
}
