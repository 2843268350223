import { Outlet } from 'react-router-dom'
import { Social } from '../../../componentes/social'

export function Layout2() {
    return (
        <>
            <main className="bg-black">
                <Outlet />
            </main>
            <aside>
                <Social />
            </aside>
        </>
    )
}
