import { Outlet } from 'react-router-dom'
import { Footer } from './footer'
import { Header } from './header'

export function MainLayout() {
    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}
