import { BtnParticipar } from '../../componentes/btnParticipar'

export function SweetPalermo() {
    return (
        <>
            <article>
                <div className="max-w-md mx-auto">
                    <img src="/assets/img/sweetpalermo/sweetpalermo.jpg" alt="Sweet Palermo" />
                </div>
                <div className="max-w-sm mx-auto px-4 mt-8">
                    <img
                        src="/assets/img/sweetpalermo/vitamina-c.jpg"
                        alt="El triple de vitamina C"
                    />
                </div>
                <div className="max-w-sm mx-auto px-4 mt-8">
                    <img
                        src="/assets/img/sweetpalermo/caracteristicas.jpg"
                        alt="Dulce, Versátil y Saludable"
                    />
                </div>
                <div className="max-w-md mx-auto mt-8">
                    <img src="/assets/img/sweetpalermo/estrella.jpg" alt="La estrella Gourmet" />
                </div>
                <div className="max-w-sm mx-auto px-4 my-8">
                    <img src="/assets/img/sweetpalermo/texto.jpg" alt="El triple de vitamina C" />
                </div>
            </article>
            <div className="w-full bg-white py-8 px-4 flex flex-col items-center">
                <BtnParticipar />
            </div>
        </>
    )
}
