import QrReader from 'modern-react-qr-reader'

export const Escanear = ({ onScan }) => {
    const handleScan = (value) => {
        console.log(value, 'escaneo')
        if (typeof value === 'string') {
            onScan(value)
        }
    }

    return (
        <QrReader
            constraints={{ facingMode: 'environment' }}
            delay={1000}
            style={{ width: '100%', heigth: '100%' }}
            onError={(e) => {
                console.log('Error', e)
            }}
            onScan={handleScan}
        />
    )
}
