import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { AuthContextProvider, UsuarioContextProvider } from '../../hooks/useUsuario'
import { Admin } from '../admin/admin'
import { Home } from '../home/home'
import { Aviso } from '../legal/aviso'
import { Bases } from '../legal/bases'
import { Cookies as CookiesPage } from '../legal/cookies'
import { Privacidad } from '../legal/privacidad'
import { Participar } from '../participar/participar'
import { Cookies } from './components/cookies'
import { Layout1 } from './components/layout1'
import { Layout2 } from './components/layout2'
import { MainLayout } from './components/mainLayout'
import { SweetPalermo } from '../sweetpalermo/sweetpalermo'

export const Core = () => {
    return (
        <AuthContextProvider>
            <UsuarioContextProvider>
                <BrowserRouter>
                    <Routes>
                        <Route element={<MainLayout />}>
                            <Route element={<Layout1 />}>
                                <Route path="/" element={<Home />} />
                            </Route>
                            <Route element={<Layout2 />}>
                                <Route path="/sweetpalermo" element={<SweetPalermo />} />
                                <Route
                                    path="/participar/:cliente"
                                    element={<Participar fromRedes={false} />}
                                />
                                <Route
                                    path="/participa/:cliente"
                                    element={<Participar fromRedes />}
                                />
                                <Route path="/legal">
                                    <Route index element={<Aviso />} />
                                    <Route path={'cookies'} element={<CookiesPage />} />
                                    <Route path={'privacidad'} element={<Privacidad />} />
                                    <Route path={'bases'} element={<Bases />} />
                                </Route>
                                <Route path="/admin/" element={<Admin />} />
                                <Route path="*" element={<Navigate replace to="/admin/" />} />
                            </Route>
                        </Route>
                    </Routes>
                    <Cookies />
                </BrowserRouter>
            </UsuarioContextProvider>
        </AuthContextProvider>
    )
}
