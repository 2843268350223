export const GoIcon = ({ color = 'white', width = '1rem' }) => {
    return (
        <svg
            width={width}
            height="100%"
            viewBox="0 0 35 35"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            style={{
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                strokeLinejoin: 'round',
            }}>
            <g transform="matrix(1,0,0,1,-901.333,-2098.67)">
                <g transform="matrix(2.66667,0,0,-2.66667,901.333,2133.33)">
                    <path
                        d="M6.5,13L13,6.5L6.5,0L5.352,1.148L9.886,5.702L0,5.702L0,7.298L9.886,7.298L5.352,11.852L6.5,13Z"
                        style={{ fillRule: 'nonzero', fill: color }}
                    />
                </g>
            </g>
        </svg>
    )
}
