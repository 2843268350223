import { css } from '@emotion/css'

export const Item = ({ image, alt, pabellon, stand }) => {
    const handleDragStart = (e) => e.preventDefault()

    return (
        <div
            className={css`
                max-width: 150px;
                max-height: 150px;
                height: 100%;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 0 1rem;
                p {
                    margin: 0;
                    color: black;
                    font-weight: normal;
                    font-size: 1rem;
                    strong {
                        font-weight: bold;
                    }
                }
            `}>
            <div
                className={css`
                    min-height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                `}>
                <img src={`/${image}`} alt={alt} onDragStart={handleDragStart} />
            </div>
            <p>
                Pabellon: <strong>{pabellon}</strong>
            </p>
            <p>
                stand: <strong>{stand}</strong>
            </p>
        </div>
    )
}
