import { css } from '@emotion/css'

export const Social = (props) => {
    return (
        <section
            {...props}
            className={css`
                p {
                    text-align: center;
                    font-weight: normal;
                    padding: 1rem;
                }
                ul {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                    li {
                        a {
                            font-size: 1.5rem;
                            color: black;
                            transition: color linear 0.2s;
                            &:hover {
                                color: #c63f3c;
                            }
                        }
                    }
                }
            `}>
            <p>Descubre más sobre el pimiento más dulce en nuestros canales</p>
            <ul>
                <li>
                    <a
                        href="https://www.facebook.com/SweetPalermoES"
                        title="Síguenos en Facebook"
                        rel="nofollow noreferrer"
                        target="_blank">
                        <span className="icono icon-facebook" />
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.instagram.com/sweetpalermoes/"
                        title="Síguenos en Instagram"
                        rel="nofollow noreferrer"
                        target="_blank">
                        <span className="icono icon-instagram" />
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.youtube.com/channel/UCdOLQ0rECRZs5M8k4zYdu8w"
                        title="Síguenos en Youtube"
                        rel="nofollow noreferrer"
                        target="_blank">
                        <span className="icono icon-youtube" />
                    </a>
                </li>
                <li>
                    <a
                        href="https://twitter.com/sweetpalermo_es?lang=es"
                        title="Síguenos en Twitter"
                        rel="nofollow noreferrer"
                        target="_blank">
                        <span className="icono icon-twitter" />
                    </a>
                </li>
            </ul>
        </section>
    )
}
