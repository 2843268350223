export const Bases = () => {
    return (
        <article className="legal">
            <h1>
                Bases de la Promoción {'"'}SABOR A PRIMERA VISTA{'"'}
            </h1>
            <p>
                Llevada a cabo por la sociedad Rijk Zwaan Ibérica, S.A., domiciliada en Ctra Viator-
                PJ.Mamí, S/N | 04120 Almería |- España, con CIF A04065231 (en adelante, «Rijk
                Zwaan»), en las que se recogen las condiciones de la promoción y participación en la
                misma. Las bases de esta promoción se encuentran a disposición de los usuarios en{' '}
                <a href="http://www.saboraprimeravista.es">www.saboraprimeravista.es</a>
            </p>

            <h2>PRIMERA.- Empresa organizadora</h2>
            <p>
                Rijk Zwaan Ibérica, S.A. organiza la presente promoción en las fechas indicadas en
                la siguiente estipulación.
            </p>

            <h2>SEGUNDA.- Periodo de participación</h2>
            <p>
                El período de participación comprenderá desde el día 8 hasta el día 10 de octubre de
                2024.
            </p>

            <h2>TERCERA.- Legitimación para participar</h2>
            <ul>
                <li>
                    <strong>3.1 Legitimación.</strong> Podrán participar en la presente promoción
                    las personas físicas mayores de 18 años con residencia legal en España y Andorra
                    que hayan aceptado las presentes bases y cada uno de sus términos y condiciones.
                </li>
                <li>
                    <strong>3.2 Exclusiones.</strong> No podrán participar los empleados de Rijk
                    Zwaan, de las agencias de publicidad, agencias de promociones o de cualesquiera
                    otras empresas relacionadas con esta promoción, así como sus familiares hasta el
                    primer grado de consanguinidad y/o afinidad.
                </li>
            </ul>

            <h2>CUARTA.- Ámbito y naturaleza</h2>
            <p>
                La participación en la presente promoción tendrá su desarrollo en todo el territorio
                nacional y Andorra. La participación en la misma tiene carácter gratuito.
            </p>

            <h2>QUINTA.- Descripción del premio</h2>
            <p>
                El premio consistirá en el regalo de 1 robot de cocina de la marca Thermomix modelo
                TM6. Se sortearán un total de 1 robot.
            </p>
            <p>
                Rijk Zwaan gestionará el envío del premio hasta la dirección que indique el
                agraciado.
            </p>

            <h2>SEXTA.- Mecánica de participación</h2>
            <p>
                Desde el 8 al 10 de octubre de 2024, ambos días inclusive, los usuarios que deseen
                participar en el sorteo deberán rellenar un formulario web alojado en
                <a href="http://www.saboraprimeravista.es">www.saboraprimeravista.es</a> y escanear
                un mínimo de 1 código QR, que encontrarán en los diferentes estands de las empresas
                asociadas a la promoción en Fruit Attraction 2024 (pabellones 3,7 y 9).
            </p>
            <p>
                Las participaciones se obtienen escaneando los diferentes códigos QR repartidos
                entre las 17 empresas asociadas a la promoción.
            </p>
            <p>
                El número máximo de participaciones es de 17. Los participantes también podrán
                participar en el sorteo a través de las redes sociales de las empresas asociadas a
                la promoción que publiquen dicha información. Igualmente, desde las cuentas
                oficiales de las redes sociales de Sweet Palermo, se podrá acceder al enlace
                correspondiente para obtener una participación más.
            </p>
            <p>
                El día 27 de octubre de 2024, Rijk Zwaan, de manera totalmente aleatoria, sorteará a
                través de la AppSorteos, y de entre todos los formularios correctamente rellenados,
                el ganador.
            </p>
            <p>
                La entrega efectiva del regalo quedará condicionada a que los participantes cumplan
                con las condiciones especificadas en las presentes bases y a la veracidad y
                exactitud de los datos facilitados por este a Rijk Zwaan. El regalo no será
                susceptible de cambio.
            </p>
            <p>Rijk Zwaan se reserva todos los derechos de esta promoción.</p>

            <h2>SÉPTIMA.- Comunicación del ganador</h2>
            <p>
                Rijk Zwaan comunicará al ganador, mediante, llamada o correo electrónico, el premio
                obtenido, con el fin de confirmar sus datos personales (nombre, apellidos y
                dirección), así como detallarle las condiciones de entrega del premio, sin coste
                alguno para el ganador.
            </p>
        </article>
    )
}
