export const useExport = () => {
    const generarDataSet = (usuarios) => {
        const filas = []

        usuarios.forEach((usuario) => {
            usuario.participaciones.forEach((participacion) => {
                const fila = {
                    id: usuario.reference?.id,
                    rol: 'Usuario',
                    nombre: usuario.nombre,
                    apellidos: usuario.apellidos,
                    empresa: usuario.empresa || 'No especificado',
                    cargo: usuario.cargo || 'No especificado',
                    email: usuario.email,
                    direccion: usuario.direccion,
                    ciudad: usuario.ciudad,
                    cp: usuario.cp,
                    pais: usuario.pais,
                    movil: usuario.movil,
                    numParticipaciones: usuario.numParticipaciones,
                    numParticipacionesRedes: usuario.numParticipacionesRedes,

                    createdAt: usuario.createdAt,
                    updatedAt: usuario.updatedAt,
                    aceptaBases: usuario.aceptaBases ? 'SI' : 'NO',
                    aceptaPrivacidad: usuario.aceptaPrivacidad ? 'SI' : 'NO',
                    aceptaInformacion: usuario.aceptaInformacion ? 'SI' : 'NO',

                    orden: participacion.orden,
                    cliente: participacion.cliente,
                    codigoCliente: participacion.codigoCliente,
                    fecha: participacion.fecha,
                    origen: participacion.origen,
                }
                filas.push(fila)
            })
        })

        filas.sort((a, b) => (a.fecha || new Date()).getTime() - (b.fecha || new Date()).getTime())

        const resultado = filas.map((f, i) => ({ ...f, index: i + 1 }))
        console.log(resultado)
        return resultado
    }

    return { generarDataSet }
}

// export type Fila = {
// 	index?: number
// 	id?: string
// 	rol: "Usuario" | "Administrador"
// 	nombre: string
// 	apellidos: string
// 	empresa: string
// 	cargo: string
// 	email: string
// 	direccion: string
// 	ciudad: string
// 	cp: string
// 	pais: string
// 	movil: string
// 	numParticipaciones: number
// 	numParticipacionesRedes: number
// 	createdAt: Date
// 	updatedAt: Date
// 	aceptaBases: "SI" | "NO"
// 	aceptaPrivacidad: "SI" | "NO"
// 	aceptaInformacion: "SI" | "NO"

// 	orden?: number
// 	cliente?: string
// 	codigoCliente?: string
// 	fecha?: Date
// 	origen?: "Redes" | "Feria"
// }
