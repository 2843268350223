import { Link } from 'react-router-dom'

export function BtnSweetPalermo({ label = 'Así es Sweet Palermo', className = '' }) {
    return (
        <Link
            to="/sweetpalermo"
            className={`uppercase font-semibold text-xl text-white rounded-full bg-[#c63f3c] px-8 py-3 mx-auto inline-block hover:scale-105 transition-transform duration-200 ${className}`}>
            {label}
        </Link>
    )
}
