import { createContext, useContext, useEffect, useState } from 'react'
import {
    getUsuarios as gUsuarios,
    login,
    logout,
    registro,
    remember,
    userSnapshot,
    usuarioSnapshot,
} from '../servicios/usuarios'

export const useAuth = () => {
    const context = useContext(AuthContext)
    if (typeof context === 'undefined') {
        throw new Error('Ha ocurrido un error y debe reiniciar la aplicación. Error autenticación.')
    }

    return { ...context, login, logout, registro, remember }
}

export const useUsuario = () => {
    const context = useContext(UsuarioContext)
    if (typeof context === 'undefined') {
        throw new Error('Ha ocurrido un error y debe reiniciar la aplicación. Error autenticación.')
    }

    const getUsuarios = async () => {
        try {
            const usuarios = await gUsuarios()
            console.log(usuarios)
            return usuarios
        } catch (error) {
            console.log(error)
        }
    }

    return { ...context, getUsuarios }
}

const AuthContext = createContext()
export const AuthContextProvider = ({ children }) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const [user, setUser] = useState()

    useEffect(() => {
        const subscription = userSnapshot(
            (u) => {
                setUser(u)
                setError(undefined)
                setLoading(false)
            },
            (err) => {
                setUser(undefined)
                setError(err)
                setLoading(false)
            },
        )
        return () => subscription()
    }, [])

    return <AuthContext.Provider value={{ user, loading, error }}>{children}</AuthContext.Provider>
}

export const UsuarioContext = createContext()
export const UsuarioContextProvider = ({ children }) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const [usuario, setUsuario] = useState()

    const { user, loading: loadingUser } = useAuth()

    useEffect(() => {
        if (typeof user?.uid !== 'undefined' && loadingUser === false) {
            const subscription = usuarioSnapshot(
                user.uid,
                (snap) => {
                    setUsuario(snap)
                    setError(undefined)
                    setLoading(false)
                },
                (err) => {
                    setUsuario(undefined)
                    setError(err)
                    setLoading(false)
                },
                () => {
                    setUsuario(undefined)
                    setError(undefined)
                    setLoading(false)
                },
            )
            return () => subscription()
        }
        setUsuario(undefined)
        setError(undefined)
        setLoading(loadingUser || false)
    }, [user?.uid, loadingUser])

    return (
        <UsuarioContext.Provider value={{ usuario, loading, error }}>
            {children}
        </UsuarioContext.Provider>
    )
}
