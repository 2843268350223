import { css } from '@emotion/css'
import { GoIcon } from '../../../componentes/icons/go'
import { Marcas } from '../../../componentes/marcas'
import { Social } from '../../../componentes/social'
import { Video } from '../../home/componentes/video'

export default function Inicio({ onParticipar }) {
    return (
        <>
            <img
                src="/assets/img/portada.jpg"
                alt="Participa y gana una experiencia en un restaurante 3 estrellas michelín"
            />
            <div
                className={css`
                    padding: 2rem 1rem 1rem;
                `}>
                <h2
                    className={css`
                        font-style: italic;
                        text-align: center;
                        font-weight: 700;
                        font-size: 1.6rem;
                        letter-spacing: 0.1px;
                        margin: 0;
                    `}>
                    ¿Cómo participar?
                </h2>
            </div>
            <Video />
            <div
                className={css`
                    padding: 0 2rem;
                    width: 100%;
                `}>
                <button
                    onClick={onParticipar}
                    type="button"
                    className={`btn-participa ${css`
                        border: 0;
                        background: #c63f3c;
                        border-radius: 4rem;
                        padding: 1rem 2rem;
                        font-size: 1.3rem;
                        font-weight: bold;
                        color: white;
                        display: flex;
                        gap: 1rem;
                        align-items: center;
                        justify-content: center;
                        animation-name: btn-participa;
                        animation-duration: 5s;
                        animation-iteration-count: infinite;
                    `}`}>
                    PARTICIPA AHORA <GoIcon width="1.2rem" />
                </button>
            </div>
            <Marcas showGurmet />
            <Social style={{ marginBottom: '1rem' }} />
        </>
    )
}
