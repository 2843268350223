import { css } from '@emotion/css'
import { useState } from 'react'
import Modal from 'react-modal'
import { GoIcon } from './icons/go'
import { Escanear } from './escanear'
//import { redirect } from 'react-router-dom'
//import { useParticipar } from '../../../hooks/useParticipacion'

export const BtnParticipar = () => {
    const [isOpen, setIsOpen] = useState(false)
    //const { getCliente } = useParticipar()
    // const navigate = useNavigate()

    const onScan = (value) => {
        console.log(value)
        //navigate(value)
        window.location.assign(value)
        // const cliente = getCliente(value, false)
        // console.log(value, cliente)
        // if (cliente) {
        //     setIsOpen(false)
        //     navigate(cliente.url)
        // }
    }

    return (
        <section className="pb-8 max-w-sm mx-auto">
            <button
                onClick={() => setIsOpen(true)}
                type="button"
                className={`btn-participa ${css`
                    border: 0;
                    background: #c63f3c;
                    border-radius: 4rem;
                    padding: 1rem 2rem;
                    font-size: 1.3rem;
                    font-weight: bold;
                    color: white;
                    display: flex;
                    gap: 1rem;
                    align-items: center;
                    justify-content: center;
                    animation-name: btn-participa;
                    animation-duration: 5s;
                    animation-iteration-count: infinite;
                `}`}>
                PARTICIPA AHORA <GoIcon width="1.2rem" />
            </button>
            <Modal
                isOpen={isOpen}
                shouldCloseOnOverlayClick
                onRequestClose={() => setIsOpen(false)}
                ariaHideApp={false}
                style={{
                    overlay: {
                        display: 'flex',
                        alignItems: 'center',
                    },
                    content: {
                        border: 0,
                        width: '100%',
                        left: 'auto',
                        right: 'auto',
                        top: 'auto',
                        bottom: 'auto',
                    },
                }}>
                <Escanear onScan={onScan} />
            </Modal>
        </section>
    )
}
