import {
    createUserWithEmailAndPassword,
    deleteUser,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
} from 'firebase/auth'
import {
    collection,
    doc,
    getDocs,
    onSnapshot,
    query,
    setDoc,
    updateDoc,
    where,
} from 'firebase/firestore'
import { getError } from '../utilidades/errores'
import { auth, db } from '../utilidades/firebase'

export const Rol = {
    USUARIO: 0,
    ADMINISTRADOR: 1,
}

const usuarioConverter = {
    toFirestore: (usuario) => {
        const usuarioMap = {
            rol: usuario.rol || Rol.USUARIO,
            nombre: usuario.nombre || 'Anónimo',
            apellidos: usuario.apellidos || '',
            empresa: usuario.empresa || '',
            cargo: usuario.cargo || '',
            email: usuario.email || '',
            movil: usuario.movil || '',
            direccion: usuario.direccion || '',
            ciudad: usuario.ciudad || '',
            cp: usuario.cp || '',
            participaciones: usuario.participaciones || [],
            pais: usuario.pais || '',
            numParticipaciones: usuario.numParticipaciones || 0,
            numParticipacionesRedes: usuario.numParticipacionesRedes || 0,
            createdAt: usuario.createdAt || new Date(),
            updatedAt: usuario.updatedAt || new Date(),
            aceptaBases: usuario.aceptaBases || false,
            aceptaPrivacidad: usuario.aceptaPrivacidad || false,
            aceptaInformacion: usuario.aceptaInformacion || false,
        }
        return usuarioMap
    },
    fromFirestore: (snap, options) => {
        if (!snap.exists || typeof snap.data(options) === 'undefined') {
            throw new Error('El usuario no existe')
        }
        try {
            const data = snap.data(options)
            const usuario = {
                reference: snap.ref,
                rol: data.rol || Rol.USUARIO,
                nombre: data.nombre || 'Anónimo',
                apellidos: data.apellidos || '',
                empresa: data.empresa || '',
                cargo: data.cargo || '',
                email: data.email || '',
                movil: data.movil || '',
                direccion: data.direccion || '',
                ciudad: data.ciudad || '',
                cp: data.cp || '',
                pais: data.pais || '',
                participaciones: (data.participaciones || []).map((p) => ({
                    orden: p.orden || 1,
                    cliente: p.cliente,
                    codigoCliente: p.codigoCliente,
                    fecha: p.fecha?.toDate() || new Date(),
                    origen: p.origen || 'Redes',
                })),
                numParticipaciones: data.numParticipaciones || 0,
                numParticipacionesRedes: data.numParticipacionesRedes || 0,
                createdAt: data.createdAt?.toDate() || new Date(),
                updatedAt: data.updatedAt?.toDate() || new Date(),
                aceptaBases: data.aceptaBases || false,
                aceptaPrivacidad: data.aceptaPrivacidad || false,
                aceptaInformacion: data.aceptaInformacion || false,
            }
            return usuario
        } catch (error) {
            console.error(error)
            throw error
        }
    },
}

export const userSnapshot = (next, onError, onComplete) => {
    return onAuthStateChanged(
        auth,
        (user) => {
            next(user || undefined)
        },
        (err) => onError?.(getError(err)),
        onComplete,
    )
}

export const usuarioSnapshot = (id, next, onError, onComplete) => {
    const reference = doc(db, 'usuarios', id).withConverter(usuarioConverter)
    return onSnapshot(reference, {
        next: (usuario) => next(usuario.exists() ? usuario.data() : undefined),
        error: (err) => onError?.(getError(err)),
        complete: onComplete,
    })
}

export const participar = async (usuario, cliente, fromRedes) => {
    try {
        if (!usuario) throw new Error('Debe iniciar sesión para participar')
        if (!cliente?.codigo || !cliente?.nombre) throw new Error('Código no válido')
        const origen = fromRedes ? 'Redes' : 'Feria'
        if (
            usuario.participaciones.find(
                (p) => p.codigoCliente === cliente.codigo && p.origen === origen,
            )
        )
            throw new Error('Eyyy!! Esta ruta ya la has completado.')
        const nuevaParticipacion = {
            cliente: cliente.nombre,
            codigoCliente: cliente.codigo,
            fecha: new Date(),
            orden: usuario.numParticipaciones + usuario.numParticipacionesRedes + 1,
            origen: fromRedes ? 'Redes' : 'Feria',
        }
        await updateDoc(usuario.reference?.withConverter(usuarioConverter), {
            participaciones: usuario.participaciones.concat(nuevaParticipacion),
            numParticipaciones: usuario.numParticipaciones + (fromRedes ? 0 : 1),
            numParticipacionesRedes: usuario.numParticipacionesRedes + (fromRedes ? 1 : 0),
            updatedAt: new Date(),
        })
    } catch (error) {
        throw getError(error)
    }
}

export const login = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password)
    } catch (error) {
        throw getError(error)
    }
}

export const logout = async () => {
    try {
        return await signOut(auth)
    } catch (error) {
        throw getError(error)
    }
}

export const remember = async (email) => {
    try {
        return await sendPasswordResetEmail(auth, email)
    } catch (error) {
        throw getError(error)
    }
}

export const registro = async (usuario, password) => {
    if (!usuario.email) throw new Error('El email es obligatorio.')
    if (!password) throw new Error('La contraseña es obligatoria.')
    try {
        const credentials = await createUserWithEmailAndPassword(auth, usuario.email, password)
        try {
            const reference = doc(db, 'usuarios', credentials.user.uid).withConverter(
                usuarioConverter,
            )
            await setDoc(reference, usuario)
        } catch (error) {
            try {
                await deleteUser(credentials.user)
            } catch (error) {
                console.error('Error eliminando user: ', error)
            }
            throw error
        }
    } catch (error) {
        console.error(error)
        throw getError(error)
    }
}

export const getUsuarios = async () => {
    try {
        const usuariosRef = collection(db, 'usuarios').withConverter(usuarioConverter)
        const q = query(usuariosRef, where('rol', '==', 0))
        const usuarios = await getDocs(q)
        return usuarios.docs.map((u) => u.data())
    } catch (error) {
        console.error(error)
        throw getError(error)
    }
}
