export const Aviso = () => {
    return (
        <article className="legal">
            <h1>Aviso legal</h1>
            <h2>
                Términos y Condiciones Generales del uso de la página web www.saboraprimeravista.es
            </h2>
            <p>
                Con el uso de esta página web usted acepta los Términos y Condiciones Generales del
                uso de la página web{' '}
                <a href="http://www.saboraprimeravista.es">www.saboraprimeravista.es</a> que se
                exponen a continuación. Asimismo manifiesta que no responsabilizará a Rijk Zwaan
                Ibérica, S.A. y/o a ninguna de las filiales del grupo (referidas en adelante de
                forma conjunta como Rijk Zwaan) de la información, recomendaciones y/o consejos
                publicados en esta página web. No está permitida la utilización de la página web{' '}
                <a href="http://www.saboraprimeravista.es">www.saboraprimeravista.es</a> sin la
                aceptación de los siguientes términos y condiciones generales:
            </p>

            <ol>
                <li>
                    La página web{' '}
                    <a href="http://www.saboraprimeravista.es">www.saboraprimeravista.es</a> está
                    elaborada con sumo cuidado. En cualquier caso, Rijk Zwaan no garantiza de
                    ninguna manera, expresa o implícitamente, que la información contenida o
                    referida en esta página web sea completa, precisa y adecuada para el propósito
                    que el usuario de esta página web pueda darle. Rijk Zwaan, por tanto, no acepta
                    responsabilidad alguna por los posibles daños resultantes de forma directa o
                    indirecta del uso de esta página web, incluyendo daños emergentes o pérdida de
                    beneficios, a menos que sean resultado de una falta de ética profesional o
                    negligencia grave por parte de Rijk Zwaan. Adicionalmente, Rijk Zwaan no será
                    responsable de posibles daños que puedan resultar directa o indirectamente de la
                    imposibilidad de utilizar esta página web.
                </li>
                <li>
                    Rijk Zwaan se reserva el derecho de modificar esta página web y sus contenidos
                    en cualquier momento, incluyendo estos Términos y Condiciones Generales del uso
                    de la página web. Rijk Zwaan no será responsable de ninguna manera de las
                    posibles consecuencias de tales modificaciones. Únicamente Rijk Zwaan tiene
                    autorización para modificar esta página web.
                </li>
                <li>
                    Esta página web puede contener enlaces o referencias a otras páginas web. Debido
                    a que Rijk Zwaan no supervisa el contenido de las páginas web de terceros, Rijk
                    Zwaan no aceptará responsabilidad alguna por la información proveniente, directa
                    o indirectamente, de las páginas web de terceros.
                </li>
                <li>
                    Esta página web puede hacer referencia a variedades que no se encuentran
                    disponibles en un país determinado. Tal referencia no implica bajo ninguna
                    circunstancia que Rijk Zwaan venda o tenga intención de vender dicha variedad en
                    ese país.
                </li>
                <li>
                    Rijk Zwaan no puede ser responsable de ninguna manera de los posibles daños
                    directos o consiguientes al hardware y/o software del ordenador como resultado
                    directo o indirecto del uso de esta página web.
                </li>
                <li>
                    Toda la información e imágenes de esta página web están protegidos por los
                    derechos de autor de Rijk Zwaan, excepto cuando se especifique lo contrario. La
                    reproducción y/o publicación total o parcial del contenido de esta página web a
                    través de impresión, fotocopia, copia digital o cualquier otra forma, solo será
                    posible bajo consentimiento previo por escrito por parte de Rijk Zwaan.
                </li>
                <li>
                    Estos Términos y Condiciones Generales del uso de la página web
                    <a href="http://www.saboraprimeravista.es">www.saboraprimeravista.es</a> están
                    sujetos a la legislación de España. Cualquier controversia se resolverá por los
                    tribunales de Almería. Sin embargo, Rijk Zwaan se reserva el derecho a entregar
                    una citación judicial al usuario de la página web de Rijk Zwaan para comparecer
                    en el juzgado de su país de residencia, o donde el usuario tenga su
                    establecimiento comercial o industrial.
                </li>
            </ol>
        </article>
    )
}
