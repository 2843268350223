import { css } from '@emotion/css'
import { useCallback, useEffect, useState } from 'react'
import ReactExport from 'react-export-excel'
import { ScaleLoader } from 'react-spinners'
import { LoginForm } from '../../componentes/loginForm'
import { useExport } from '../../hooks/useExport'
import { useAuth, useUsuario } from '../../hooks/useUsuario'
import { Rol } from '../../servicios/usuarios'

export const Admin = () => {
    const [usuarios, setUsuarios] = useState([])
    const [filas, setFilas] = useState([])
    const { logout } = useAuth()
    const { usuario, loading, error, getUsuarios } = useUsuario()
    const { generarDataSet } = useExport()

    const cerrarSesion = async () => {
        try {
            await logout()
        } catch (error) {
            console.error(error)
        }
    }

    const obtenerUsuarios = useCallback(async () => {
        if (usuario?.reference?.id) {
            const users = await getUsuarios()
            setUsuarios(users || [])
            console.log('Generamos usuarios', users)
            setFilas(generarDataSet(users || []))
        }
    }, [usuario])

    useEffect(() => {
        obtenerUsuarios()
    }, [obtenerUsuarios])

    if (loading) {
        return (
            <div
                className={css`
                    padding-top: 5rem;
                    min-height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                `}>
                <ScaleLoader width="2px" color="red" />
            </div>
        )
    }
    if (error) {
        return <div className="">{error}</div>
    }
    if (!usuario || usuario.rol !== Rol.ADMINISTRADOR) {
        console.log(usuario)
        return <LoginForm botonLabel="INICIAR SESIÓN" />
    }

    const { ExcelFile } = ReactExport
    const { ExcelSheet } = ReactExport.ExcelFile
    const { ExcelColumn } = ReactExport.ExcelFile

    return (
        <>
            <p>
                Hay {filas.length} participaciones de {usuarios.length} usuarios
            </p>
            {filas.length > 0 && (
                <ExcelFile
                    filename="Participaciones"
                    element={<button type="button">Descargar Excel</button>}>
                    <ExcelSheet data={filas} name="Participaciones">
                        <ExcelColumn label="ID" value="index" />
                        <ExcelColumn
                            label="NOMBRE"
                            value={(col) => `${col.nombre} ${col.apellidos}`}
                        />
                        <ExcelColumn
                            label="DIRECCION"
                            value={(col) =>
                                `${col.direccion}, ${col.cp} (${col.ciudad}) ${col.pais}`
                            }
                        />
                        <ExcelColumn label="EMPRESA" value="empresa" />
                        <ExcelColumn label="CARGO" value="cargo" />
                        <ExcelColumn label="EMAIL" value="email" />
                        <ExcelColumn label="TELEFONO" value="movil" />
                        <ExcelColumn
                            label="F. REGISTRO"
                            value={(col) => col.createdAt?.toLocaleString()}
                        />
                        <ExcelColumn label="ORDEN PART" value="orden" />
                        <ExcelColumn
                            label="CLIENTE"
                            value={(col) =>
                                col.codigoCliente === 'saboraprimeravista'
                                    ? 'Sabor a primera vista'
                                    : col.cliente
                            }
                        />
                        <ExcelColumn label="COD. CLIENTE" value="codigoCliente" />
                        <ExcelColumn
                            label="ORIGEN"
                            value={(col) =>
                                col.codigoCliente === 'saboraprimeravista'
                                    ? 'Enlace Red'
                                    : col.origen
                            }
                        />
                        <ExcelColumn label="FECHA" value={(col) => col.fecha?.toLocaleString()} />
                        <ExcelColumn label="TOTAL PART. FERIA" value="numParticipaciones" />
                        <ExcelColumn label="TOTAL PART. REDES" value="numParticipacionesRedes" />
                        <ExcelColumn
                            label="TOTAL PARTICIPACIONES"
                            value={(col) => col.numParticipaciones + col.numParticipacionesRedes}
                        />
                        <ExcelColumn label="INFO COMERCIAL" value="aceptaInformacion" />
                    </ExcelSheet>
                </ExcelFile>
            )}
            <div
                className={css`
                    padding: 5rem 0 0 0;
                `}>
                <button type="button" onClick={cerrarSesion}>
                    Cerrar sesión
                </button>
            </div>
        </>
    )
}
