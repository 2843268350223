import { getApp, getApps, initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}
const app = getApps().length ? getApp() : initializeApp(firebaseConfig)

const authService = getAuth(app)
const dbService = getFirestore(app)

if (window.location.hostname === 'localhost') {
    connectAuthEmulator(authService, 'http://localhost:9099')
    connectFirestoreEmulator(dbService, 'localhost', 5002)
}

export const auth = authService
export const db = dbService

export default app
