import { css } from '@emotion/css'
import CookieConsent from 'react-cookie-consent'

export const Cookies = () => {
    return (
        <CookieConsent
            acceptOnScroll={false}
            debug={false}
            buttonText="Aceptar"
            declineButtonText="Rechazar"
            cookieName="cookiesAceptadas"
            flipButtons
            enableDeclineButton
            ariaAcceptLabel="Aceptar cookies"
            ariaDeclineLabel="Rechazar cookies"
            style={{ background: '#000', opacity: 0.9, padding: '1rem' }}
            buttonWrapperClasses={css`
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 1rem;
                button {
                    margin: 0;
                }
            `}
            buttonStyle={{
                background: '#5E7E3F',
                fontSize: '1rem',
                color: 'white',
                margin: 0,
            }}
            declineButtonStyle={{
                background: '#E1001E',
                fontSize: '1rem',
                marginRight: '1rem',
                color: 'white',
                margin: 0,
            }}>
            <div
                className={css`
                    color: white;
                    font-weight: normal;
                `}>
                <p>
                    Este sitio web utiliza cookies para mejorar el servicio y analizar el tráfico
                    web. Puede obtener más infomación visitando nuestra{' '}
                    <a
                        target="_blank"
                        title="Política de cookies"
                        href="/legal/cookies"
                        className={css`
                            color: white;
                            font-weight: bold;
                            transition: color linear 0.2s;
                            &:hover {
                                color: #e1001e;
                            }
                        `}>
                        Política de cookies
                    </a>
                </p>
            </div>
        </CookieConsent>
    )
}
