import { Link } from 'react-router-dom'

export const Header = () => {
    return (
        <header className="bg-black pb-8">
            <div className="wrapper flex justify-between items-center ">
                <Link to="/">
                    <img
                        src="/assets/img/logo.png"
                        alt="saboraprimeravista"
                        className="max-w-28 md:max-w-48"
                    />
                </Link>
                <img
                    src="/assets/img/anio.png"
                    alt="Sabor del año"
                    className="max-w-20 md:max-w-36"
                />
            </div>
        </header>
    )
}

/**
 * <div
            className={css`
                display: grid;
                grid-template-columns: 2fr 1fr;
                align-items: center;
                justify-content: space-between;
                gap: 2rem;
                padding: 1rem 0 2rem;
                background: black;
            `}>
            <Link
                to="/"
                className={css`
                    display: flex;
                    align-items: center;
                `}>
                <img
                    src="/assets/img/logo.png"
                    alt="saboraprimeravista"
                    className={css`
                        width: 100%;
                        max-width: 180px;
                    `}
                />
            </Link>
            <img
                src="/assets/img/anio.png"
                alt="Sabor del año"
                className={css`
                    width: 100%;
                    max-width: 100px;
                    justify-self: end;
                `}
            />
        </div>
 */
