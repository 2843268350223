export const Cookies = () => {
    return (
        <article className="legal">
            <h1>Definición de cookies</h1>
            <p>
                Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas
                páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y
                recuperar información sobre los hábitos de navegación de un usuario o de su equipo
                y, dependiendo de la información que contengan y de la forma en que utilice su
                equipo, pueden utilizarse para reconocer al usuario.
            </p>

            <h2>Tipos de cookies</h2>
            <h3>1. Tipos de cookies, según la entidad que las gestione:</h3>
            <ul>
                <li>
                    <strong>Cookies Propias:</strong> Son aquellas que se envían al equipo terminal
                    del usuario desde un equipo o dominio gestionado por el propio editor y desde
                    que se presta el servicio solicitado por el usuario.
                </li>
                <li>
                    <strong>Cookies de terceros:</strong> Son aquellas que se envían al equipo
                    terminal del usuario desde un equipo o dominio que no es gestionado por el
                    editor, sino por otra entidad que trata los datos obtenidos a través de las
                    cookies.
                </li>
            </ul>

            <h3>2. Tipos de cookies, según su finalidad:</h3>
            <ul>
                <li>
                    <strong>Cookies Técnicas:</strong> Son aquellas que permiten al usuario la
                    navegación a través de la página web, plataforma o aplicación y la utilización
                    de las diferentes opciones o servicios que en ella existen.
                </li>
                <li>
                    <strong>Cookies de Análisis:</strong> Son aquellas que permiten al responsable
                    de las mismas, el seguimiento y análisis del comportamiento de los usuarios de
                    los sitios web a los que están vinculadas.
                </li>
            </ul>

            <h3>3. Tipos de cookies, según el plazo de tiempo que permanecen activadas:</h3>
            <ul>
                <li>
                    <strong>Cookies Persistentes:</strong> Son las diseñadas para que los datos
                    sigan almacenados en el terminal y pueden ser accedidos y tratados durante un
                    periodo definido por el responsable de la cookie, y que puede ir de unos minutos
                    a varios años.
                </li>
                <li>
                    <strong>Cookies de Sesión:</strong> Son las diseñadas para recabar y almacenar
                    datos mientras el usuario accede a una página web. Se suelen emplear para la
                    prestación del servicio solicitado por el usuario en una sola ocasión.
                </li>
            </ul>

            <h2>Cookies necesarias</h2>
            <h2>Cookies de estadísticas</h2>

            <h2>Bloqueo o eliminación de las cookies</h2>
            <p>
                Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo
                mediante la configuración de las opciones del navegador instalado en su ordenador.
                Pinche en el navegador que utilice para obtener detalles de cómo puede gestionar las
                cookies:
            </p>
            <ul>
                <li>Chrome</li>
                <li>Internet Explorer</li>
                <li>Firefox</li>
                <li>Safari</li>
            </ul>
            <div className="overflow-auto">
                <table>
                    <caption>Detalles de Cookies Utilizadas</caption>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Proveedor</th>
                            <th>Propósito</th>
                            <th>Caducidad</th>
                            <th>Tipo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>cookies</td>
                            <td>www.saboraprimeravista.es</td>
                            <td>
                                Almacena el estado de consentimiento de cookies del usuario para el
                                dominio actual.
                            </td>
                            <td>1 año</td>
                            <td>HTTP</td>
                        </tr>
                        <tr>
                            <td>cookies-preferencias</td>
                            <td>-</td>
                            <td>Almacena el estado de consentimiento de cookies de preferencias</td>
                            <td>1 año</td>
                            <td>HTTP</td>
                        </tr>
                        <tr>
                            <td>cookies-estadisticas</td>
                            <td>-</td>
                            <td>Almacena el estado de consentimiento de cookies de estadísticas</td>
                            <td>1 año</td>
                            <td>HTTP</td>
                        </tr>
                        <tr>
                            <td>cookies-marketing</td>
                            <td>-</td>
                            <td>Almacena el estado de consentimiento de cookies de marketing</td>
                            <td>1 año</td>
                            <td>HTTP</td>
                        </tr>
                        <tr>
                            <td>_ga</td>
                            <td>google-analytics.com</td>
                            <td>
                                Registra una identificación única que se utiliza para generar datos
                                estadísticos acerca de cómo utiliza el visitante el sitio web.
                            </td>
                            <td>2 años</td>
                            <td>HTTP</td>
                        </tr>
                        <tr>
                            <td>_gid</td>
                            <td>google-analytics.com</td>
                            <td>
                                Registra una identificación única que se utiliza para generar datos
                                estadísticos acerca de cómo utiliza el visitante el sitio web.
                            </td>
                            <td>1 día</td>
                            <td>HTTP</td>
                        </tr>
                        <tr>
                            <td>_gat</td>
                            <td>google-analytics.com</td>
                            <td>
                                Utilizado por Google Analytics para controlar la tasa de peticiones
                            </td>
                            <td>1 día</td>
                            <td>HTTP</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h2>Acceso a datos</h2>
            <p>Las cookies de nuestro sitio web son únicamente utilizadas por:</p>
            <ul>
                <li>Google Inc. (Analíticas web)</li>
                <li>Youtube. (Mostrar vídeo embebido)</li>
            </ul>
        </article>
        // <div>
        //     <h3>Política de cookies</h3>
        //     <h5>Definición de cookies</h5>
        //     <p>
        //         Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas
        //         páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y
        //         recuperar información sobre los hábitos de navegación de un usuario o de su equipo
        //         y, dependiendo de la información que contengan y de la forma en que utilice su
        //         equipo, pueden utilizarse para reconocer al usuario.
        //     </p>
        //     <h5>Tipos de cookies</h5>
        //     <h6>1. Tipos de cookies, según la entidad que las gestione:</h6>
        //     <ol type="a">
        //         <li>
        //             Cookies Propias: Son aquellas que se envían al equipo terminal del usuario desde
        //             un equipo o dominio gestionado por el propio editor y desde que se presta el
        //             servicio solicitado por el usuario.
        //         </li>
        //         <li>
        //             Cookies de terceros: Son aquellas que se envían al equipo terminal del usuario
        //             desde un equipo o dominio que no es gestionado por el editor, sino por otra
        //             entidad que trata los datos obtenidos a través de las cookies.
        //         </li>
        //     </ol>
        //     <h6>2. Tipos de cookies, según su finalidad:</h6>
        //     <ol type="a">
        //         <li>
        //             Cookies Técnicas: Son aquellas que permiten al usuario la navegación a través de
        //             la página web, plataforma o aplicación y la utilización de las diferentes
        //             opciones o servicios que en ella existen.
        //         </li>
        //         <li>
        //             Cookies de Análisis: Son aquellas que permiten al responsable de las mismas, el
        //             seguimiento y análisis del comportamiento de los usuarios de los sitios web a
        //             los que están vinculadas.
        //         </li>
        //     </ol>
        //     <h6>3.-Tipos de cookies, según el plazo de tiempo que permanecen activadas:</h6>
        //     <ul>
        //         <li>
        //             Cookies de Sesión: Son las diseñadas para recabar y almacenar datos mientras el
        //             usuario accede a una página web. Se suelen emplear para la prestación del
        //             servicio solicitado por el usuario en una sola ocasión.
        //         </li>
        //         <li>
        //             Cookies Persistentes: Son las diseñadas para que los datos sigan almacenados en
        //             el terminal y pueden ser accedidos y tratados durante un periodo definido por el
        //             responsable de la cookie, y que puede ir de unos minutos a varios años.
        //         </li>
        //     </ul>
        //     <p>
        //         La web <b>saboraprimeravista</b> utiliza estas cookies propias y de terceros, todas
        //         en el dominio
        //     </p>
        //     <h6>Cookies necesarias</h6>
        //     <table className="cookies-utilizadas">
        //         <thead>
        //             <tr>
        //                 <th>Nombre</th>
        //                 <th>Proveedor</th>
        //                 <th>Propósito</th>
        //                 <th>Caducidad</th>
        //                 <th>Tipo</th>
        //             </tr>
        //         </thead>
        //         <tbody>
        //             <tr>
        //                 <td>cookies</td>
        //                 <td>saboraprimeravista.es</td>
        //                 <td>
        //                     Almacena el estado de consentimiento de cookies del usuario para el
        //                     dominio actual.
        //                 </td>
        //                 <td>1 año</td>
        //                 <td>HTTP</td>
        //             </tr>
        //             <tr>
        //                 <td>cookies-preferencias</td>
        //                 <td>saboraprimeravista.es</td>
        //                 <td>Almacena el estado de consentimiento de cookies de preferencias</td>
        //                 <td>1 año</td>
        //                 <td>HTTP</td>
        //             </tr>
        //             <tr>
        //                 <td>cookies-estadisticas</td>
        //                 <td>saboraprimeravista.es</td>
        //                 <td>Almacena el estado de consentimiento de cookies de estadísticas</td>
        //                 <td>1 año</td>
        //                 <td>HTTP</td>
        //             </tr>
        //             <tr>
        //                 <td>cookies-marketing</td>
        //                 <td>saboraprimeravista.es</td>
        //                 <td>Almacena el estado de consentimiento de cookies de marketing</td>
        //                 <td>1 año</td>
        //                 <td>HTTP</td>
        //             </tr>
        //             <tr>
        //                 <td>cookies-no-clasificadas</td>
        //                 <td>saboraprimeravista.es</td>
        //                 <td>Almacena el estado de consentimiento de cookies no clasificadas</td>
        //                 <td>1 año</td>
        //                 <td>HTTP</td>
        //             </tr>
        //         </tbody>
        //     </table>
        //     <h6>Cookies de estadísticas</h6>
        //     <table className="cookies-utilizadas">
        //         <thead>
        //             <tr>
        //                 <th>Nombre</th>
        //                 <th>Proveedor</th>
        //                 <th>Propósito</th>
        //                 <th>Caducidad</th>
        //                 <th>Tipo</th>
        //             </tr>
        //         </thead>
        //         <tbody>
        //             <tr>
        //                 <td>_ga</td>
        //                 <td>google-analytics.com</td>
        //                 <td>
        //                     Registra una identificación única que se utiliza para generar datos
        //                     estadísticos acerca de cómo utiliza el visitante el sitio web.
        //                 </td>
        //                 <td>2 años</td>
        //                 <td>HTTP</td>
        //             </tr>
        //             <tr>
        //                 <td>_gid</td>
        //                 <td>google-analytics.com</td>
        //                 <td>
        //                     Registra una identificación única que se utiliza para generar datos
        //                     estadísticos acerca de cómo utiliza el visitante el sitio web.
        //                 </td>
        //                 <td>1 día</td>
        //                 <td>HTTP</td>
        //             </tr>
        //             <tr>
        //                 <td>_gat</td>
        //                 <td>google-analytics.com</td>
        //                 <td>Utilizado por Google Analytics para controlar la tasa de peticiones</td>
        //                 <td>1 día</td>
        //                 <td>HTTP</td>
        //             </tr>
        //         </tbody>
        //     </table>
        //     <h5>Bloqueo o eliminación de las cookies</h5>
        //     <p>
        //         Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo
        //         mediante la configuración de las opciones del navegador instalado en su ordenador.
        //         Pinche en el navegador que utilice para obtener detalles de cómo puede gestionar las
        //         cookies
        //     </p>
        //     <ul>
        //         <li>
        //             <a
        //                 target="_blank"
        //                 rel="nofollow noreferrer"
        //                 title="Cómo bloquear o eliminar cookies en chrome"
        //                 href="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647">
        //                 Chrome
        //             </a>
        //         </li>
        //         <li>
        //             <a
        //                 target="_blank"
        //                 rel="nofollow noreferrer"
        //                 title="Cómo bloquear o eliminar cookies en chrome"
        //                 href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9">
        //                 Internet Explorer
        //             </a>
        //         </li>
        //         <li>
        //             <a
        //                 target="_blank"
        //                 rel="nofollow noreferrer"
        //                 title="Cómo bloquear o eliminar cookies en chrome"
        //                 href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">
        //                 Firefox
        //             </a>
        //         </li>
        //         <li>
        //             <a
        //                 target="_blank"
        //                 rel="nofollow noreferrer"
        //                 title="Cómo bloquear o eliminar cookies en chrome"
        //                 href="http://support.apple.com/kb/ph5042">
        //                 Safari
        //             </a>
        //         </li>
        //     </ul>
        //     <h5>Acceso a datos</h5>
        //     <p>Las cookies de nuestro sitio web son únicamente utilizadas por:</p>
        //     <ul>
        //         <li>Google Inc. (Analíticas web)</li>
        //         <li>Youtube. (Mostrar vídeo embebido)</li>
        //     </ul>
        // </div>
    )
}
